<template>
  <div style="background: #F7F9FC;color: #000;height: 100%;">
    <van-nav-bar title="消费记录" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div style="margin-top:58px;height: 100%;background: #F7F9FC;">
      <div class="mian" v-for="(item,idx) in list" :key="idx">
        <div class="js_dian">
          <span class="left">{{item.garageName}}</span>
          <span class="right">￥{{item.amountActual?item.amountActual.toFixed(2):'0.00'}}</span>
        </div>

        <div class="xianmu" @click="totiaozhuan(item.id)">
          <div class="xianmu_A">
            <span>车牌：</span>
            <span class="chepaih">{{item.carNo}}</span>
            <img class="shipin" v-if="item.videoUrl" @click.stop="tobofang(item.videoUrl)" src="../../assets/img/shipin2.png" alt />
          </div>
          <div class="xianmu_A">
            <span>项目：</span>
            <span>{{item.itemName}}</span>
          </div>
          <div class="xianmu_b">
            <span>配件：</span>
            <span>{{item.partName}}</span>
          </div>
        </div>
        <div class="jiesuan" v-if="item.settlementStatus != 1" @click="jiesuan(item)">
          <span class="jies">结算</span>
        </div>
        <div class="js_shijian" v-else>
          <span class="left">结算时间：</span>
          <span class="right" style="color: #666;">{{item.settlementDate}}</span>
        </div>
      </div>
      <!-- <div class="mian">
        <div class="dian">
          <span class="left">胜创连锁合路店</span>
          <span class="right">￥500.00</span>
        </div>
        <div class="xianmu">
          <div class="xianmu_A">
            <span>项目：</span>
            <span>更换左前大灯/xxxxx/xxxxxxx/更换左前大灯左前大灯</span>
          </div>
          <div class="xianmu_b">
            <span>配件：</span>
            <span>更换左前大灯/xxxxx/xxxxxxx/更换左前大灯</span>
          </div>
        </div>
        <div class="shijian">
          <span class="left">结算时间：</span>
          <span class="right" style="color: #666;">2019-07-18 12:41:58</span>
        </div>
      </div>-->
      <van-empty v-if="list.length < 1" description="暂无数据~" />
    </div>
    <van-popup v-model="show" @close="guanbi">
      <!-- <video
        id="myvideo"
        :src="videoSrc"
        :poster="videoImg"
        :muted="muteStatus"
        :autoplay="playStatus"
        height="414"
        width="720"
      >your browser does not support the video tag</video>-->
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      ></video-player>
    </van-popup>
  </div>
</template>

<script>
import orderModel from "../../api/order";
import utils from "../../assets/utils";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  //   mixins: [preventBack], //注入
  components: {},
  data() {
    return {
      list: [],
      show: false,
      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "",
            src: "" //url地址
          }
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true //全屏按钮
        }
      },
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      active: "0"
    };
  },
  methods: {
    tobofang(item){
      // this.playerOptions.sources[0].src = 'http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4'
       this.playerOptions.sources[0].src = item
      this.show = true
    },
    guanbi(){
    console.log('sdadsa')
    this.$refs.videoPlayer.player.pause()
    },
    jiesuan(item) {
      this.$router.push({
        path: "ka_pay",
        query: {
          isok: false
        }
      });
      localStorage.setItem("xiaofei", JSON.stringify(item));
    },
    totiaozhuan(id) {
      this.$router.push({
        path: "consume_details",
        query: {
          id: id,
          title: "消费记录详情"
        }
      });
    },
    onLoad() {
      const data = {
        gid: this.shangjia.id,
        customerId: this.userInfo ? this.userInfo.customerId : ""
        // customerId: 161
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      orderModel
        .weixiulist(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },

    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.mian {
  margin: 12px 12px;
  background: #fff;
  padding-bottom: 10px;
  border-radius: 4px;
  overflow: hidden;
}
.js_dian {
  padding: 0 12px;
  overflow: hidden;
  font-size: 14px;
  line-height: 42px;

  color: #fff;
  background: #df3d48;
}
.chepaih {
  display: inline-block;
  // background: #000093;
  background: url(../../assets/img/chepaibj.png) no-repeat;
  background-size: 100% 100%;
  color: #fff;
  padding: 0 4px;
}
.xianmu {
  padding: 0 12px;
  overflow: hidden;
  font-size: 14px;
  color: #000;
  margin-top: 8px;
  // border-top: 1px solid #f5f5f5;
}
.xianmu_A {
  line-height: 25px;
  margin-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.xianmu_b {
  line-height: 25px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-bottom: 12px;
}
.jiesuan {
  line-height: 25px;
  color: #df3d48;
  font-size: 15px;
  text-align: center;
}
.jies {
  display: inline-block;
  border: 2px solid #df3d48;

  padding: 0 35px;
}
.js_shijian {
  font-size: 14px;
  padding: 0 12px;
}
.shipin {
  width: 34px;
  float: right;
  margin-top: 2px;
}
.van-popup {
  width: 100%;
}
#myvideo {
  width: 100%;
  // height: 220px;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
</style>